<template>
  <section
    class="hero is-fullheight is-pulled-up"
    :class="{ 'has-background-info': $experian }"
  >
    <div class="hero-body mobile-background" :style="hero">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-4-widescreen is-6-desktop is-8-tablet">
            <div class="content">
              <span class="title is-5 auth-text">
                <span class="icon is-medium">
                  <i
                    class="fal"
                    :class="[isExperian ? 'fa-key' : 'fa-smile-beam']"
                  />
                </span>
                <span class="is-marginless">
                  Let's create your new password
                </span>
              </span>
            </div>
            <form @submit.prevent="submit">
              <div class="field">
                <label for="password" class="label auth-text">
                  <span class="is-valigned">Password</span>
                  <a
                    v-tippy="{ placement: 'right', html: '#password-tooltip' }"
                    class="icon is-small is-valigned auth-text"
                  >
                    <i class="fal fa-info-circle" />
                  </a>
                </label>
                <PasswordTooltip id="password-tooltip" style="display: none" />
                <input
                  type="password"
                  name="password"
                  v-model="password"
                  :class="{ 'is-danger': errors.has('password') }"
                  class="input is-white is-medium"
                  v-validate.disable="{ required: true, min: 8, regex }"
                />
              </div>
              <span class="help has-text-danger" v-if="errors.has('password')">
                {{ errors.first('password') }}
              </span>
              <div class="field">
                <label for="password2" class="label auth-text"
                  >Password confirmation</label
                >
                <input
                  type="password"
                  :class="{ 'is-danger': errors.has('password_confirmation') }"
                  v-validate.disable="{ required: true, confirmed: password }"
                  name="password2"
                  v-model="password2"
                  data-vv-as="password"
                  class="input is-white is-medium"
                />
              </div>
              <span class="help has-text-danger" v-if="errors.has('password2')">
                {{ errors.first('password2') }}
              </span>
              <div class="field has-text-danger" v-if="error">
                <span class="icon is-valigned">
                  <i class="fal fa-times" />
                </span>
                <span class="is-valigned">{{ error }}</span>
              </div>
              <div class="buttons is-right">
                <button
                  class="button is-medium is-soft is-rounded"
                  :class="{ 'is-loading': $wait.is('loading') }"
                  :disabled="$wait.is('loading')"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import PasswordTooltip from './PasswordTooltip'
import { passwordReset } from 'modules/account/services'
import { heroStyle } from 'modules/ui/services'
export default {
  name: 'AuthPasswordReset',
  components: { PasswordTooltip },
  data: () => ({
    password: '',
    password2: '',
    error: false
  }),
  methods: {
    async submit() {
      const valid = await this.$validator.validate()
      if (valid) {
        this.$wait.start('loading')
        try {
          const { email, password, password2, token } = this
          const params = { email, password, password2, token }
          await passwordReset(params)
          this.$router.push({ name: 'auth-login' })
          this.$notify({ group: 'success', text: 'Password updated' })
        } catch (err) {
          const fallback = 'We were unable to complete your request'
          this.error = err?.response?.data?.error?.message ?? fallback
        } finally {
          this.$wait.end('loading')
        }
      }
    }
  },
  computed: {
    regex() {
      return /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$%^&*()+_\-=}{[\]|:;"/?.><,`~]).{8,}$/g
    },
    isExperian() {
      return this.$experian
    },
    token() {
      return this.$route.query.token
    },
    email() {
      return decodeURI(this.$route.query.email).replace(' ', '+')
    },
    hero() {
      return this.$mq !== 'mobile' ? heroStyle() : { background: 'none' }
    }
  }
}
</script>
